import {makePercent} from "./MakePercent";
import person from "./maps/person.svg";
import React from "react";
import {MapItem, MapSize} from "./MapData";

interface MapItemProps {
    item: MapItem,
    size: MapSize
}

export const AnimatedMapItem = ({item, size}: MapItemProps) => {
    const { x = 0, y = 0, visible = true } = item;
    return (
        <div id={"item-" + item.title} data-item-title={item.title} className="animated-map__item"
             style={{left: makePercent(x, size.width), top: makePercent(y, size.width) }}>
            <img src={person} height="24" alt={item.title}/>
            <div className="animated-map__item_title">{item.title}</div>
            <div className="animated-map__item_description">{item.description}</div>
        </div>
    )
}