import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {useParams} from "react-router-dom";
import {PathParams} from "../pages/PathParams";
import {Page} from "../pages/Page";
import {useBook} from "../context/BookContext";
import CollectionLoader from "../collection/CollectionLoader";
import LibraryLoader from "../library/LibraryLoader";
import {findBookTitle} from "../state/FindBookTitle";
import Menu from "../navigation/Menu";
import {buildCollectionCoverUrl} from "../utils/buildCollectionCoverUrl";
import DocTitle from "../navigation/DocTitle";
import BookLoader from "../book/BookLoader";
import {MetaDetailsCard} from "./MetaDetailsCard";
import Lenke from "../utils/Lenke";
import "./MetaPage.css";
import MetaBrowserPanel from "./MetaBrowserPanel";
import MetaLoader from "./MetaLoader";

const MetaPage = () => {
    const {state} = useBook();
    const {collectionID, bookID, meta} = useParams<PathParams>();
    const book = state.books?.find(b => b.bookID === state.bookID);
    const people = book?.people || [];
    const places = book?.places || [];
    const items = book?.items || [];
    const list = people.concat(places, items).filter( (it) => it !== "")
    const listIndex = list.indexOf(meta)
    var prevUrl = "";
    var prevTitle = "";
    var nextUrl = "";
    var nextTitle = "";
    if (listIndex === 0){
        prevUrl = "/library/" + collectionID + "/" + bookID + "/"
        nextUrl = "/metadata/" + collectionID + "/" + bookID + "/" + list[ listIndex + 1 ];
        prevTitle = list[ listIndex - 1 ];
        nextTitle = list[ listIndex + 1 ];
    } else if (listIndex === list.length - 1) {
        prevUrl = "/metadata/" + collectionID + "/" + bookID + "/" + list[ listIndex - 1 ];
        prevTitle = list[ listIndex - 1 ];
        // nextUrl = findNextBookUrl(state) || ""
        nextUrl = ""
        nextTitle = ""
    } else {
        prevUrl = "/metadata/" + collectionID + "/" + bookID + "/" + list[ listIndex - 1 ];
        nextUrl = "/metadata/" + collectionID + "/" + bookID + "/" + list[ listIndex + 1 ];
        prevTitle = list[ listIndex - 1 ];
        nextTitle = list[ listIndex + 1 ];
    }
    return (
        <Page className={"meta-page book_" + bookID}>
            <LibraryLoader>
                <CollectionLoader collectionID={collectionID}>
                    <BookLoader collectionID={collectionID} bookID={bookID}>

                        <Menu
                            bookID={bookID}
                            label={findBookTitle(state)}
                            home={"/library/" + collectionID + "/" + bookID}
                            icon={buildCollectionCoverUrl(collectionID)}
                            prev={prevUrl}
                            next={nextUrl}
                            photo={state.photo}
                        />

                        <MetaBrowserPanel bookCollectionID={collectionID} bookID={bookID}/>
                            <Row>
                                <Col>
                                    <Lenke href={prevUrl} className="meta-page__prev-button meta_page__btn">{ prevTitle }</Lenke>
                                </Col>
                                <Col>
                                    <Lenke href={nextUrl} className="meta-page__next-button meta_page__btn">{ nextTitle }</Lenke>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <MetaLoader bookCollectionID={collectionID} metaID={meta}>
                                        <MetaDetailsCard />
                                    </MetaLoader>
                                </Col>
                            </Row>
                        <DocTitle title={state.bookCollectionID + " - " + findBookTitle(state) + " " + meta}/>

                    </BookLoader>
                </CollectionLoader>
            </LibraryLoader>
        </Page>
    );
};

export default MetaPage
