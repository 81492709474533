import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import {PathParams} from "../pages/PathParams";
import {Page} from "../pages/Page";
import "./CollectionPage.css";
import {useBook} from "../context/BookContext";
import LibraryLoader from "../library/LibraryLoader";
import CollectionLoader from "./CollectionLoader";
import Menu from "../navigation/Menu";
import {buildCollectionCoverUrl} from "../utils/buildCollectionCoverUrl";
import {findCollectionTitle} from "../state/FindCollectionTitle";
import DocTitle from "../navigation/DocTitle";
import BookCard from "./BookCard";

const CollectionPage = () => {
    const {state} = useBook();
    const {collectionID} = useParams<PathParams>();
    return (
        <Page className="collection-page">
            <LibraryLoader>
                <CollectionLoader collectionID={collectionID}>
                    <Menu
                        label={findCollectionTitle(state)}
                        home="/library"
                        icon={buildCollectionCoverUrl(collectionID)}
                        photo={state.photo}
                    />
                    <Row>
                        {state.books?.map(
                            (c, index) => (
                                <Col sm={12} md={6} lg={6} xl={4} key={"book_" + index}>
                                    <Link to={"/library/" + collectionID + "/" + c.bookID}
                                          className={"collection-card-link"}>
                                        <BookCard key={"coll" + index} collectionID={collectionID} bookItem={c}/>
                                    </Link>
                                </Col>
                            ))
                        }
                    </Row>
                        <DocTitle title={state.bookCollectionID + ""}/>
                </CollectionLoader>
            </LibraryLoader>
        </Page>
    );
}

export default CollectionPage

