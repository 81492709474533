import React from 'react';
import {useParams} from "react-router-dom";
import {PathParams} from "../pages/PathParams";
import {Page} from "../pages/Page";
import "./ChapterPage.css";
import {buildBookCoverUrl} from "../utils/buildBookCoverUrl";
import {useBook} from "../context/BookContext";
import LibraryLoader from "../library/LibraryLoader";
import CollectionLoader from "../collection/CollectionLoader";
import BookLoader from "../book/BookLoader";
import ChapterLoader from "./ChapterLoader";
import {BookProgress} from "./BookProgress";
import {findChapterCount} from "../state/FindChapterCount";
import Menu from "../navigation/Menu";
import {buildCollectionCoverUrl} from "../utils/buildCollectionCoverUrl";
import {findBookTitle} from "../state/FindBookTitle";
import DocTitle from "../navigation/DocTitle";
import ChapterContentsCard from "./ChapterContentsCard";
import {Col, Row} from "react-bootstrap";

const ChapterPage = () => {
    const { state } = useBook();
    const { collectionID, bookID, chapterNr } = useParams<PathParams>();

    return (
        <Page className={"chapter-page book_" + state.bookID} backgroundImage={buildBookCoverUrl(collectionID, bookID)}>
            <LibraryLoader>
                <CollectionLoader collectionID={collectionID}>
                    <BookLoader collectionID={collectionID} bookID={bookID}>
                        <ChapterLoader collectionID={collectionID} bookID={bookID} chapterNr={chapterNr}>

                            <Menu
                                bookID={bookID}
                                label={findBookTitle(state) + " " + chapterNr}
                                home={"/library/"+collectionID+"/"+bookID}
                                prev={chapterNr === "1" ? ("/library/"+collectionID+"/"+bookID) : state.prevUrl}
                                next={state.nextUrl}
                                photo={state.photo}
                                hints={state.hints}
                                icon={buildCollectionCoverUrl(collectionID)} />

                            <BookProgress chapter={parseInt(chapterNr)} max={findChapterCount(state)}/>

                            {(state.chapter && state.chapterHtml) &&
                                <Row>
                                    <Col>
                                        <ChapterContentsCard title={state.chapter.title} body={state.chapterHtml} />
                                    </Col>
                                </Row>
                            }

                            <DocTitle title={state.bookCollectionID + " - " + findBookTitle(state) + " " + state.chapterNr}/>

                        </ChapterLoader>
                    </BookLoader>
                </CollectionLoader>
            </LibraryLoader>
        </Page>
    );
}

export default ChapterPage;
