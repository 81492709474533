import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Page} from "../pages/Page";
import {useBook} from "../context/BookContext";
import Menu from "../navigation/Menu";
import {ErrorCard} from "../library/ErrorCard";

interface ErrorPageProps {
    message: string
}

const ErrorPage = (props: ErrorPageProps) => {
    const { state } = useBook();
    return (
        <Page className="library">
            <Menu label="Alexandria" home="/" photo={state.photo}/>
            <Row>
                <Col md={1} sm={1}></Col>
                <Col>
                    <ErrorCard message={props.message}/>
                </Col>
                <Col md={1} sm={1}></Col>
            </Row>
        </Page>
    );
}

export default ErrorPage;
