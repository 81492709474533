interface DocTitleProps {
    title: string
}


const DocTitle = (props: DocTitleProps) => {
    document.title = props.title
    return (<span></span>);
}

export default DocTitle
