import BooksAPI from "../api/BooksAPI";
import {useBook} from "../context/BookContext";
import {Actions} from "../state/Actions";
import {useEffect} from "react";
import {BookSpinner} from "../library/BookSpinner";
import {Status} from "../api/Status";
import ErrorPage from "../security/ErrorPage";

interface BookLoaderProps {
    children: any;
    collectionID: string;
    bookID: string;
}

const BookLoader = (props: BookLoaderProps) => {
    const { state, dispatch } = useBook();
    const { collectionID, bookID } = props;
    useEffect(() => {
        window.scroll(0,0)
        if ((!state.chapters || state.bookID !== bookID)){
            BooksAPI.GetBook(state.token || '', collectionID, bookID).then(
                response => {
                    dispatch({
                        type: Actions.GotoBook,
                        payload: {
                            chapters: response.chapters,
                            bookCollectionID: collectionID,
                            bookID: bookID,
                            status: Status.Ready
                        }
                    });
                }
            ).catch(e => {
                dispatch({
                    type: Actions.GotoBook,
                    payload: {
                        bookCollectionID: collectionID,
                        bookID: bookID,
                        status: e.status
                    }
                });
            })
        }
        // eslint-disable-next-line
    }, [bookID, state.chapters, state.bookID, state.bookStatus])
    if (state.bookID !== bookID){
        return (
            <BookSpinner title="Laster bok..."/>
        );
    }
    switch (state.bookStatus) {
        case Status.Forbidden:
            return (
                <ErrorPage message="You dont have access to this book"/>
            )
        case Status.Error:
            return (
                <ErrorPage message="Could not load book!"/>
            )
        case Status.Unauthorized:
            return (
                <ErrorPage message="You must login to read book"/>
            )
        case Status.Timeout:
            return (
                <ErrorPage message="Timeout loading book"/>
            )
        case Status.Ready:
            return (
                <div>{props.children}</div>
            )
        default:
            return (
                <BookSpinner title="Loading book..."/>
            );
    }
}

export default BookLoader
