export enum Actions {
    Reset,
    SavedToken,
    GotoLibrary,
    GotoCollection,
    GotoBook,
    GotoChapter,
    Metadata,
    Error,
    Login,
    Logout,
    GotoCollectionMetadata,
    Meta,
    GotoSearch,
    SearchKeyword,
    NextSearchIndex,
}