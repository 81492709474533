import React from "react";
import "./MetaCard.css";

export type MetaCardProps = {
    title: string,
    icon?: string;
    bookCollectionID: string;
}

const MetaCard = (props: MetaCardProps) => {
    return (
        <div className="meta-card">
            <div className="meta-card__image">
                <img src={props.icon} alt={"Bilde av " + props.title}/>
            </div>
            <div className="meta-card__text">
                <div className="meta-card__title">{props.title}</div>
            </div>
        </div>
    )
}

export default MetaCard
