import {Status} from "./Status";
import {BookCollection} from "./BookCollection";
import {BookItem} from "./BookItem";
import {ChapterContents} from "./ChapterContents";
import {Metadata} from "./Metadata";
import {Meta} from "./Meta";
import {SearchHit} from "./SearchHit";
import {ChapterItem} from "./ChapterItem";

const handleResponseStatus = (response: Response) => {
    switch (response.status) {
        case 200:
            return response.json();
        case 401:
            return Promise.reject({status: Status.Unauthorized});
        case 404:
            return Promise.reject({status: Status.NotFound});
        case 403:
            return Promise.reject({status: Status.Forbidden});
        case 500:
            return Promise.reject({status: Status.Error});
        default:
            return Promise.reject({status: Status.Error});
    }
};

interface LibraryResponse {
    status: number
    bookCollections?: BookCollection[]
}

const GetLibrary = (token: string, timeout: number = 10000): Promise<LibraryResponse> => {
    return Promise.race([
        new Promise((resolve, reject) => setTimeout(() => reject({
            status: Status.Timeout
        }), timeout))
            .then(() => ({
                status: Status.Timeout
            }))
        ,
        fetch('/api/books', {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            credentials: 'include',
            method: 'GET'
        })
            .then(handleResponseStatus)
            .then((json) => ({
                status: Status.Ready,
                bookCollections: json
            }))
    ]);
};



interface CollectionResponse {
    status: number
    books?: BookItem[]
}

const GetCollection = (token: string, collectionID: string, timeout: number = 10000): Promise<CollectionResponse> => {
    return Promise.race([
        new Promise((resolve, reject) => setTimeout(() => reject({
            status: Status.Timeout
        }), timeout))
            .then(() => {
                return {
                    status: Status.Timeout
                };
            }),
        fetch('/api/books/' + collectionID, {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            credentials: 'include',
            method: 'GET'
        })
            .then(handleResponseStatus)
            .then((json) => ({
                status: Status.Ready,
                books: json
            }))
    ]);
};

interface BookResponse {
    status: number
    chapters?: ChapterItem[]
}

const GetBook = (token: string, collectionID: string, bookID: string, timeout: number = 10000): Promise<BookResponse> => {
    return Promise.race([
        new Promise((resolve, reject) => setTimeout(() => reject({
            status: Status.Timeout
        }), timeout))
            .then(() => {
                return {
                    status: Status.Timeout
                };
            }),
        fetch('/api/books/' + collectionID + "/" + bookID, {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            credentials: 'include',
            method: 'GET'
        })
            .then(handleResponseStatus)
            .then((json) => ({
                status: 200,
                chapters: json
            }))
    ]);
};


interface ChapterResponse {
    status: number
    chapter?: ChapterContents
}

const GetChapter = (token: string, collectionID: string, bookID: string, chapterNr: number, timeout: number = 10000): Promise<ChapterResponse> => {
    return Promise.race([
        new Promise((resolve, reject) => setTimeout(() => reject({
            status: Status.Timeout
        }), timeout))
            .then(() => {
                return {
                    status: Status.Timeout
                };
            }),
        fetch('/api/books/' + collectionID + "/" + bookID + "/" + chapterNr, {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            credentials: 'include',
            method: 'GET'
        })
            .then(handleResponseStatus)
            .then((json) => ({
                status: Status.Ready,
                chapter: json
            }))
    ]);
};

interface ChapterMetadataResponse {
    status: number
    metadata?: Metadata
}

const GetChapterMetadata = (token: string, collectionID: string, bookID: string, chapterNr: number, timeout: number = 10000): Promise<ChapterMetadataResponse> => {
    return Promise.race([
        new Promise((resolve, reject) => setTimeout(() => reject({
            status: Status.Timeout
        }), timeout))
            .then(() => {
                return {
                    status: Status.Timeout
                };
            }),
        fetch('/api/metadata/' + collectionID + "/" + bookID + "/" + chapterNr, {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            credentials: 'include',
            method: 'GET'
        })
            .then(handleResponseStatus)
            .then((json) => ({
                status: Status.Ready,
                metadata: json
            }))
    ]);
};

interface MetaResponse {
    status: number
    meta?: Meta[]
}

const GetMeta = (token: string, collectionID: string, metaID: string, timeout: number = 10000): Promise<MetaResponse> => {
    return Promise.race([
        new Promise((resolve, reject) => setTimeout(() => reject({
            status: Status.Timeout
        }), timeout))
            .then(() => {
                return {
                    status: Status.Timeout
                };
            }),
        fetch('/api/meta/' + collectionID + "/" + metaID, {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            credentials: 'include',
            method: 'GET'
        })
            .then(handleResponseStatus)
            .then((json) => ({
                status: Status.Ready,
                meta: json
            }))
    ]);
};

interface SearchResponse {
    status: number
    hits?: SearchHit[]
}


const GetSearch = (token: string, keyword: string, timeout: number = 10000): Promise<SearchResponse> => {
    return Promise.race([
        new Promise((resolve, reject) => setTimeout(() => reject({
            status: Status.Timeout
        }), timeout))
            .then(() => {
                return {
                    status: Status.Timeout
                };
            }),
        fetch('/api/search/verse/' + keyword , {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            credentials: 'include',
            method: 'GET'
        })
            .then(handleResponseStatus)
            .then((json) => ({
                status: Status.Ready,
                hits: json
            }))
    ]);
};

// eslint-disable-next-line
export default { GetLibrary, GetCollection, GetBook, GetChapter, GetChapterMetadata, GetMeta, GetSearch }