import BooksAPI from "../api/BooksAPI";
import {useBook} from "../context/BookContext";
import {Actions} from "../state/Actions";
import React, {useEffect} from "react";
import {BookSpinner} from "../library/BookSpinner";
import {Status} from "../api/Status";
import ErrorPage from "../security/ErrorPage";
import ChapterContentsCard from "./ChapterContentsCard";

interface ChapterLoaderProps {
    children: any;
    collectionID: string;
    bookID: string;
    chapterNr: string;
}

const ChapterLoader = (props: ChapterLoaderProps) => {
    const { state, dispatch } = useBook();
    const { collectionID, bookID, chapterNr } = props;
    const chapterNrInt = parseInt(chapterNr)
    useEffect(() => {
        if (state.token && (!state.chapter || state.chapterNr !== chapterNrInt)){
            window.scroll(0,0)
            BooksAPI.GetChapter(state.token, collectionID, bookID, chapterNrInt ).then(
                response => {
                    dispatch({
                        type: Actions.GotoChapter,
                        payload: {
                            chapter: response.chapter,
                            bookCollectionID: collectionID,
                            bookID: bookID,
                            chapterNr: chapterNrInt,
                            status: Status.Ready
                        }
                    });
                    BooksAPI.GetChapterMetadata(state.token || '', collectionID, bookID, chapterNrInt ).then(
                        r => {
                            dispatch({
                                type: Actions.Metadata,
                                payload: {
                                    metadata: r.metadata,
                                    bookCollectionID: collectionID,
                                    bookID: bookID,
                                    chapterNr: chapterNrInt,
                                    status: Status.Ready
                                }
                            });
                        }
                    ).catch(e => {
                        console.error("Error in response", e);
                        dispatch({
                            type: Actions.Metadata,
                            payload: {
                                status: e.status,
                                bookCollectionID: collectionID,
                                bookID: bookID,
                                chapterNr: chapterNrInt
                            }
                        });
                    })
                }
            ).catch(e => {
                dispatch({
                    type: Actions.GotoChapter,
                    payload: {
                        error: e,
                        bookCollectionID: collectionID,
                        bookID: bookID,
                        chapterNr: chapterNrInt
                    }
                });
            })
        }
        // eslint-disable-next-line
    }, [chapterNr, state.chapter, state.chapterNr])
    if (state.chapterNr !== chapterNrInt){
        return (
            <ChapterContentsCard title="Kapittel"><BookSpinner/></ChapterContentsCard>
        );
    }

    switch (state.chapterStatus) {
        case Status.Forbidden:
            return (
                <ErrorPage message="Du har ikke tilgang til dette kapitlet"/>
            )
        case Status.Error:
            return (
                <ErrorPage message="Klarte ikke laste kapittel"/>
            )
        case Status.Unauthorized:
            return (
                <ErrorPage message="Du må logge inn først"/>
            )
        case Status.Timeout:
            return (
                <ErrorPage message="Klarte ikke laste kapittel (tidsavbrudd)"/>
            )
        case Status.Ready:
            return (
                <div>{props.children}</div>
            )
        default:
            return (
                <ChapterContentsCard title="Kapittel"><BookSpinner title="Laster..."/></ChapterContentsCard>
            );
    }
}

export default ChapterLoader
