import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';

const NotFoundPage = () => {
  return (
    <Container>
        <Row>
            <Col md={6}>
                NotFound
            </Col>
        </Row>
    </Container>
  );
}

export default NotFoundPage;
