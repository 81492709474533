import {Card, CardImg, Col, Row} from "react-bootstrap";
import {ReadingTime} from "../utils/ReadingTime";
import {ListSeparator} from "../utils/ListSeparator";
import React from "react";
import "./BookCoverCard.css";

interface BookCoverProps {
    collectionID: string,
    bookID: string,
    src: string,
    title: string;
    subtitle: string;
    category?: string;
    wordCount?: number;
    keywords?: string[];
    description?: string;
}

const BookCoverCard = (props: BookCoverProps) => {
    return (
        <Card className="book-cover-card">
            <CardImg src={props.src}/>
            <Card.Header>
                <Row>
                    <Col>{props.category}</Col>
                    <Col><ReadingTime wordCount={props.wordCount}/></Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Subtitle>{props.subtitle}</Card.Subtitle>
                <Card.Title>{props.title}</Card.Title>
                <ListSeparator className="book-cover-card__keywords" keywords={props.keywords}/>
                <Card.Text>{props.description}</Card.Text>
            </Card.Body>
        </Card>
    );
}


export default BookCoverCard