import React from "react";
import {BookCollection} from "../api/BookCollection";
import {Card, CardImg, Col, Row} from "react-bootstrap";
import {buildCollectionCoverUrl} from "../utils/buildCollectionCoverUrl";
import {ReadingTime} from "../utils/ReadingTime";
import "./CollectionCard.css";

 const CollectionCard = (props: { c: BookCollection }) => {
    return (
        <Card className="collection-card">
            <CardImg src={buildCollectionCoverUrl(props.c.id)}/>
            <Card.Body>
                <Card.Title>{props.c.subtitle}</Card.Title>
                <Card.Subtitle>{props.c.title}</Card.Subtitle>
                <Card.Text>{props.c.description}</Card.Text>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>Bøker: {props.c.bookCount}</Col>
                    <Col>Orginalspråk: {props.c.originalLanguage}</Col>
                    <Col><ReadingTime wordCount={props.c.wordCount}/></Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default CollectionCard
