const mapChapterHtml = (body: string) => {
    if (body.indexOf("<h2>") === -1) {
        return '<div class="sections"><div class="section">' + body + '</div></div>';
    }
    let arr = body.split("<h2>").filter( a => a.length > 0);
    let html = "";
    for (let x=0; x<arr.length; x++) {
        const hasHeader = arr[x].includes("</h2>");
        if (x > 0) {
            html += '<div class="section-separator"></div>';
        }
        html += '<div class="section">' + (hasHeader ? '<h2>' : '') + arr[x] + '</div>';
    }
    return '<div class="sections">' + html + '</div>';
}

export default mapChapterHtml
