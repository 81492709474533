import {useBook} from "../context/BookContext";
import {useEffect} from "react";
import {Actions} from "../state/Actions";
import {BookSpinner} from "../library/BookSpinner";

interface LibraryLoaderProps {
    children: any
}

const TokenProvider = (props: LibraryLoaderProps) => {
    const { state, dispatch } = useBook();
    useEffect(() => {
        if (state.tokenRead !== true) {
            const savedToken = localStorage.getItem("token") || undefined;
            const savedUser = localStorage.getItem("user") || undefined;
            const savedPhoto = localStorage.getItem("photo") || undefined;
            const savedEmail = localStorage.getItem("email") || undefined;
            dispatch({
                type: Actions.SavedToken,
                payload: {
                    token: savedToken,
                    user: savedUser,
                    photo: savedPhoto,
                    email: savedEmail
                }
            });
        }
        // eslint-disable-next-line
    }, [state.token, state.tokenRead])
    if (state.tokenRead !== true) {
        return (
            <BookSpinner title="Please wait..."/>
        );
    }
    return (<div>{props.children}</div>);
}

export default TokenProvider
