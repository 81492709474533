import {ListGroup, ListGroupItem} from "react-bootstrap";
import {ChapterItem} from "../api/ChapterItem";
import Lenke from "../utils/Lenke";
import React from "react";
import "./ChaptersList.css"

interface ChapterListingsProps {
    bookID: string;
    bookCollectionID: string;
    chapters: ChapterItem[]
}

const ChaptersList = (props: ChapterListingsProps) => {
    return (
        <ListGroup className="chapter-listings">
            {props.chapters?.map(
                (c: ChapterItem, index: number) => (
                    <ListGroupItem key={"coll" + index} className="chapter-listings-item">
                        <Lenke className="chapter-listings-item-link"
                               href={"/library/" + props.bookCollectionID + "/" + props.bookID + "/" + c.chapterNr}>
                            <span className="chapter-listings-item-title">{c.title}</span>
                            <span className="chapter-listings-item-nr">{c.chapterNr}</span>
                        </Lenke>
                    </ListGroupItem>
                ))
            }
        </ListGroup>
    )
}

export default ChaptersList
