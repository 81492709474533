import BooksAPI from "../api/BooksAPI";
import {useBook} from "../context/BookContext";
import {Actions} from "../state/Actions";
import React, {useEffect} from "react";
import {Status} from "../api/Status";
import {Redirect} from "react-router-dom";
import ErrorPage from "../security/ErrorPage";
import {BookSpinner} from "../library/BookSpinner";

interface SearchHitsLoaderProps {
    keyword?: string
    children: any
}

const SearchHitsLoader = (props: SearchHitsLoaderProps) => {
    const { state, dispatch } = useBook();
    useEffect(() => {
        if (!state.searchStatus && props.keyword) {
            BooksAPI.GetSearch(state.token || '', props.keyword).then(
                response => {
                    dispatch({
                        type: Actions.GotoSearch,
                        payload: {
                            status: Status.Ready,
                            searchHits: response.hits
                        }
                    });
                }
            ).catch(e => {
                dispatch({
                    type: Actions.GotoSearch,
                    payload: {
                        status: e.status
                    }
                });
            })
        }
        // eslint-disable-next-line
    }, [state.searchStatus])
    if (!state.token) {
        return (
            <Redirect to="/login"/>
        )
    }
    switch (state.searchStatus) {
        case Status.Forbidden:
            return (
                <ErrorPage message="Du har ikke tilgang til å søke"/>
            )
        case Status.Error:
            return (
                <ErrorPage message="Klarte ikke søke"/>
            )
        case Status.Unauthorized:
            return (
                <Redirect to="/"/>
            )
        case Status.Timeout:
            return (
                <ErrorPage message="Klarte ikke å søke (timeout)"/>
            )
        case Status.Ready:
            return (
                <div>{props.children}</div>
            )
        default:
            return (
                <BookSpinner title="Søker..."/>
            );
    }
}

export default SearchHitsLoader
