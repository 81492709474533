import React from "react";
import icon from "./item.svg"
import MetaCard, {MetaCardProps} from "../MetaCard";

const ItemCard = (props: MetaCardProps) => {
    return (
        <MetaCard icon={icon} title={props.title} bookCollectionID={props.bookCollectionID}/>
    )
}

export default ItemCard
