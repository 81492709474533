import {Spinner} from "react-bootstrap";
import React from "react";
import "./BookSpinner.css";

interface BookSpinnerProps {
    title?: string
    className?: string
}

export const BookSpinner = (props: BookSpinnerProps) => {
    return (
        <div className={"book-spinner " + props.className}>
            <Spinner animation={"border"}/>
        </div>
    );
}