import {MapData, MapEvent} from "./MapData";
import {makePercent} from "./MakePercent";
import {AnimatedMapItem} from "./AnimatedMapItem";
import React from "react";
import {MapTextLabel} from "./MapTextLabel";
import "./AnimatedMap.css";

interface AnimatedMapProps {
    mapData: MapData
}

export const AnimatedMap = ({mapData}: AnimatedMapProps) => {
    let { size, maps, items = [], events = [] } = mapData
    let index = 0;
    let handle: any;
    const setItemPosition = (item: string, x: number | undefined, y: number | undefined, visible: boolean | undefined) => {
        if (item === "Abel"){
            console.log("Abel", x, y, visible)
        }
        const el = document.getElementById("item-" + item)
        if (el != null) {
            if (x) {
                el.style.left = makePercent(x, size.width)
            }
            if (y){
                el.style.top = makePercent(y, size.height)
            }
            el.style.opacity = (visible) ? "1" : "0"
        }
    }
    const setMap = (title: string) => {
        const layers = document.getElementsByClassName("map-layer")
        for (let x=0; x<layers.length; x++){
            let map = layers[x]
            const isCurrent = map.id === ("map-layer-"+title)
            map.className = isCurrent ? "map-layer animated-map_layeractive" : "map-layer animated-map__layerinactive"
        }
    }
    const setNarrator = (text: string) => {
        const el = document.getElementById("map-caption-1")
        if (el != null) {
            el.innerText = text;
        }
    }
    const updateItem = (evt: MapEvent) => {
        if (evt.item) {
            setItemPosition(evt.item, evt.x, evt.y, evt.visible)
        }
        if (evt.items && evt.items.length > 0) {
            evt.items.forEach(i => {
                setItemPosition(i, evt.x, evt.y, evt.visible)
            })
        }
        if (evt.map) {
            setMap(evt.map)
        }
        setNarrator(evt.narrator || "")
    }
    const playNext = () => {
        if (index < events.length) {
            const evt = events[index]
            updateItem(evt)
            handle = setTimeout(playNext, evt.duration * 1000)
        }
        index++
    }
    const resetTimeline = () => {
        index = 0;
        items.forEach(item => {
            setItemPosition(item.title, 0, 0, false)
        })
    }
    const handleClick = () => {
        if (index > 0) {
            index = 0;
            clearTimeout(handle)
            resetTimeline()
        }
        playNext()
    }
    return (
        <div className="animated-map">
            <div className={"animated-map__controls"}>
                {events.length > 0 &&
                    <button onClick={handleClick}>Start</button>
                }
            </div>
            <div id={"map-caption-1"} className="animated-map__narrator">
            </div>
            <div className="animated-map__items">
                {items.map((v, index) => (
                    <AnimatedMapItem key={"popup-" + index} size={size} item={v}/>
                ))}
            </div>
            <div className="animated-map__layers">
                {maps.map((m, indeks) => (
                    <div
                        key={"map-layer-" + m.title}
                        id={"map-layer-" + m.title}
                        className={"map-layer " + (indeks === 0 ? "animated-map_layeractive" : "animated-map__layerinactive")}>
                            <img src={m.url} className={"animated-map__bg"} alt={m.title}/>
                            {m.labels && m.labels.map((label, index) => (
                                <MapTextLabel key={"label-"+index} label={label} size={size}/>
                            ))}
                    </div>
                ))}

            </div>
        </div>
    );
}

