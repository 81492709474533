import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import "./CollectionMetadataPage.css";
import {Page} from "../pages/Page";
import {useBook} from "../context/BookContext";


const CollectionMetadataPage = () => {
    const { state } = useBook();
    if (!state.collection){
        return (<div>Missing</div>);
    }
    return (
        <Page className="collection-metadata-page">
            <Row>
               <Col>
                   <Card className="collection-meta-card">
                       <Card.Body>
                           <Card.Title>{state.collection.title}</Card.Title>
                           <Card.Subtitle>{state.collection.subtitle}</Card.Subtitle>
                           <Card.Text>
                               {state.collection.description}
                           </Card.Text>
                       </Card.Body>
                   </Card>
               </Col>
            </Row>
        </Page>
    )
}

export default CollectionMetadataPage
