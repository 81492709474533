import React from "react";

interface ListSeparatorProps {
    keywords?: String[],
    className?: String
    children?: any
}

export const ListSeparator = (props: ListSeparatorProps) => {
    return (
        <div className={"keyword-list " + props.className || ""}>
            {props.keywords?.map((k, index) =>
                (
                    <span key={"keyword-list__internal" + index}>
                        {index > 0 &&
                            <span className="keyword-list__separator" key={"keyword-list_separator" + index}>{props.children || "-"}</span>
                        }
                        <span className="keyword-list__item" key={"keyword-list_" + index}>{k}</span>
                    </span>
                )
            )}
        </div>
    )
}