import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LibraryPage from "./library/LibraryPage";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import CollectionPage from "./collection/CollectionPage";
import BookPage from "./book/BookPage";
import ChapterPage from "./chapter/ChapterPage";
import {BookProvider} from "./context/BookContext";
import Swiper from "./utils/Swiper";
import CollectionMetadataPage from "./collection/CollectionMetadataPage";
import WelcomePage from "./welcome/WelcomePage";
import TokenProvider from "./security/TokenProvider";
import ExpiredPage from "./security/ExpiredPage";
import MetaPage from "./meta/MetaPage";
import NotFoundPage from "./pages/NotFoundPage";
import MapPage from "./map/MapPage";

class App extends React.Component {
    render() {
        return (
            <BookProvider>
                <TokenProvider>
                    <Swiper/>
                    <Router>
                        <Switch>
                            <Route path="/privacy">
                                <div>Privacy is coming</div>
                            </Route>
                            <Route path="/terms">
                                <div>Terms is coming</div>
                            </Route>
                            <Route path="/login">
                                <ExpiredPage />
                            </Route>
                            <Route path="/library" exact={true}>
                                <LibraryPage/>
                            </Route>
                            <Route path="/library/:collectionID" exact={true}>
                                <CollectionPage/>
                            </Route>
                            <Route path="/meta/:collectionID" exact={true}>
                                <CollectionMetadataPage/>
                            </Route>
                            <Route path="/library/:collectionID/:bookID" exact={true}>
                                <BookPage/>
                            </Route>
                            <Route path="/metadata/:collectionID/:bookID/:meta" exact={true}>
                                <MetaPage/>
                            </Route>
                            <Route path="/library/:collectionID/:bookID/:chapterNr" exact={true}>
                                <ChapterPage/>
                            </Route>
                            <Route path="/kart" exact={true}>
                                <MapPage/>
                            </Route>
                            <Route path="/" exact={true}>
                                <WelcomePage/>
                            </Route>
                            <Route>
                                <NotFoundPage/>
                            </Route>
                        </Switch>
                    </Router>
                </TokenProvider>
            </BookProvider>
        );
    }
}

export default App;
