import {MapData} from "../MapData";
import worldmap from "./world.jpg";
import mesopotamia from "./mesopotamia.jpg";



export const genesisMapData: MapData = {
    size: {
        width: 2560,
        height: 1401,
    },
    maps: [
        {
            title: "Mesopotamia",
            url: mesopotamia,
            labels: [
                { x: 760, y: 600, title: "Eufrat" },
                { x: 1370, y: 500, title: "Hiddekel" },
                { x: 1900, y: 1300, title: "Den persiske bukt" },
            ]
        },
        {
            title: "Verden",
            url: worldmap,
            labels: [
                { x: 30, y: 500, title: "Verden" },
            ]
        },
    ],
    items: [
        {title: "Adam"},
        {title: "Eva"},
        {title: "Kain", description: "Jordbruker"},
        {title: "Abel", description: "Sauegjeter"},

        {title: "Henok"},
        {title: "Irad"},
        {title: "Mehujael"},
        {title: "Metusjael"},
        {title: "Lamek"},
        {title: "Jabal", description: "Bor i telt og har buskap"},
        {title: "Jubal", description: "Spiller lyre og fløyte"},
        {title: "Tubalkain", description: "Arbeider med bronse og jern"},
        {title: "Na'ama"},

        {title: "Set"},
        {title: "Enosj", description: "Påkaller herrens navn"},
        {title: "Kenan"},
        {title: "Mahalalel"},
        {title: "Jared"},
        {title: "Henok"},
        {title: "Metusjalah"},
        {title: "Lamek"},
        {title: "Noah"},

        {title: "Sem", description: "Nord"},
        {title: "Ham", description: "Sør"},
        {title: "Jafet", description: "Øst"},

    ],
    events: [
        { duration: 0, map: "Mesopotamia" },
        { x: 1150, y: 650, visible: true, duration: 1, item: "Adam", narrator: "Adam blir født"},
        { x: 1300, y: 650, visible: true, duration: 1, item: "Eva", narrator: "Eva blir født"},
        { duration: 1, narrator: "Eva og Adam spiser av frukten på treet som er midt i hagen"},
        { duration: 1, narrator: "Eva og Adam får forstand"},
        { x: 1150, y: 800, visible: true, duration: 1, item: "Kain", narrator: "Kain blir født"},
        { x: 1300, y: 800, visible: true, duration: 1, item: "Abel", narrator: "Abel blir født"},
        { x:0, y:0, visible: false, duration: 1, item: "Abel", narrator: "Abel blir drept"},

        { duration: 2, narrator: "Kains slekt..."},

        { x: 2000, y: 100, visible: true, duration: 1, item: "Henok"},
        { x: 2000, y: 300, visible: true, duration: 1, item: "Irad"},
        { x: 2000, y: 500, visible: true, duration: 1, item: "Mehujael"},
        { x: 2000, y: 700, visible: true, duration: 1, item: "Metusjael"},
        { x: 2000, y: 900, visible: true, duration: 1, item: "Lamek"},
        { x: 1700, y: 1100, visible: true, duration: 1, item: "Jabal"},
        { x: 1900, y: 1100, visible: true, duration: 1, item: "Jubal"},
        { x: 2100, y: 1100, visible: true, duration: 1, item: "Tubalkain"},
        { x: 2300, y: 1100, visible: true, duration: 1, item: "Na'ama"},

        { duration: 2, narrator: "Adams slekt..."},

        { x: 200, y: 0, visible: true, duration: 1, item: "Set"},
        { x: 200, y: 150, visible: true, duration: 1, item: "Enosj"},
        { x: 200, y: 300, visible: true, duration: 1, item: "Kenan"},
        { x: 200, y: 450, visible: true, duration: 1, item: "Mahalalel"},
        { x: 200, y: 600, visible: true, duration: 1, item: "Jared"},
        { x: 200, y: 750, visible: true, duration: 1, item: "Henok"},
        { x: 200, y: 900, visible: true, duration: 1, item: "Metusjalah"},
        { x: 200, y: 1050, visible: true, duration: 1, item: "Lamek"},
        { x: 200, y: 1200, visible: true, duration: 1, item: "Noah"},

        { duration: 2, narrator: "Noahs slekt..."},

        { x: 500, y: 0, visible: true, duration: 1, item: "Sem"},
        { x: 500, y: 150, visible: true, duration: 1, item: "Ham"},
        { x: 500, y: 300, visible: true, duration: 1, item: "Jafet"},


        { duration: 0, map: "Verden" },

        { duration: 2, narrator: "Flommen kommer og Noah med familie flykter mot Araratfjellene..."},

        { x: 1400, y: 600, visible: true, duration: 0, item: "Noah"},
        { x: 1450, y: 300, visible: true, duration: 0, item: "Jafet"},
        { x: 1500, y: 650, visible: true, duration: 0, item: "Sem"},
        { x: 1550, y: 800, visible: true, duration: 0, item: "Ham"},

        { duration: 2, narrator: "Alle unntatt Noah dør"},

        { visible: false, duration: 0, items: ["Adam", "Eva", "Kain", "Henok", "Irad", "Mehujael", "Metusjael", "Lamek", "Jabal", "Jubal", "Tubalkain", "Na'ama",  "Set", "Enosj", "Kenan", "Mahalalel", "Jared", "Henok", "Metusjalah", "Lamek"]},


        { x: 1740, y: 720, visible: true, duration: 3, item: "Noah"},
        { x: 1100, y: 300, visible: true, duration: 2, item: "Jafet"},
        { x: 1540, y: 650, visible: true, duration: 2, item: "Sem"},
        { x: 1000, y: 800, visible: true, duration: 2, item: "Ham"},
    ]
}