import {useBook} from "../context/BookContext";
import {Actions} from "../state/Actions";
import {Redirect} from "react-router-dom";
import React from "react";

const ExpiredPage = () => {
    const { dispatch } = useBook();
    dispatch({
        type: Actions.Logout,
        payload: {
        }
    })
    return (
        <Redirect to="/"/>
    );
}

export default ExpiredPage
