import {useBook} from "../context/BookContext";
import {useEffect} from "react";
import BooksAPI from "../api/BooksAPI";
import {Actions} from "../state/Actions";
import {Status} from "../api/Status";
import {BookSpinner} from "../library/BookSpinner";
import ErrorPage from "../security/ErrorPage";

interface MetaLoaderProps {
    metaID: string
    bookCollectionID: string
    children: any
}

const MetaLoader = (props: MetaLoaderProps) => {
    const { state, dispatch } = useBook();
    useEffect(() => {
        if (!state.metaStatus || state.metaID !== props.metaID ){
            console.log("Metaloader", props)
            BooksAPI.GetMeta(state.token || '', props.bookCollectionID, props.metaID).then(
                response => {
                    dispatch({
                        type: Actions.Meta,
                        payload: {
                            meta: response.meta,
                            metaID: props.metaID,
                            status: Status.Ready
                        }
                    });
                }
            ).catch(e => {
                dispatch({
                    type: Actions.Meta,
                    payload: {
                        metaID: props.metaID,
                        status: e.status
                    }
                });
            })
        }

        // eslint-disable-next-line
    }, [state.meta, state.metaStatus, props.metaID, state.bookCollectionID])
    if (state.metaID !== props.metaID){
        return (
            <BookSpinner title="Loading collection..."/>
        );
    }
    switch (state.metaStatus) {
        case Status.Forbidden:
            return (
                <ErrorPage message="You dont have access to this meta"/>
            )
        case Status.Error:
            return (
                <ErrorPage message="Could not load meta!"/>
            )
        case Status.Unauthorized:
            return (
                <ErrorPage message="You must login to read meta"/>
            )
        case Status.Timeout:
            return (
                <ErrorPage message="Timeout loading meta"/>
            )
        case Status.Ready:
            return (
                <div className="metaloader">{props.children}</div>
            )
        case Status.NotFound:
            return (
                <div className="metaloader">
                    <h3>{props.metaID}</h3>
                    <p>Ingen tilgjengelig informasjon</p>
                </div>
            )
        default:
            return (
                <BookSpinner title="Loading meta..."/>
            );
    }
}

export default MetaLoader
