import "./Menu.css";
import Lenke from "../utils/Lenke";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import accountIcon from "./icons/user.svg";
import {useState} from "react";
import {Actions} from "../state/Actions";
import {useBook} from "../context/BookContext";
import GoogleLoginProvider from "../security/GoogleLoginProvider";
import SearchButton from "../search/SearchButton";
import {Hint} from "../api/Hint";


interface MenuProps {
    icon?: string;
    label?: string;
    home?: string;
    prev?: string;
    next?: string;
    account?: string;
    bookID?: string;
    photo?: string;
    token?: string;
    hints?: Hint[];
}

const Menu = (props: MenuProps) => {
    const { state, dispatch } = useBook();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLogoutClicked = () => {
        dispatch({
            type: Actions.Logout,
            payload: {}
        })
    }
    return (
        <div className={"menu book_" + props.bookID || ''}>
            <div className="menu__bg">
            <Container>
                <Row>
                    <Col>
                        <Lenke href={props.home || ''} disabled={!props.home} className="menu__label">
                            {props.label}
                        </Lenke>

                        {props.photo &&
                        <div className="menu__buttons">

                            {props.prev &&
                            <Lenke id='prevButton'
                                   href={props.prev || ''}
                                   className="menu_btn">
                                -
                            </Lenke>
                            }
                            {props.next &&
                            <Lenke id='nextButton'
                                   href={props.next || ''}
                                   className="menu_btn">
                                +
                            </Lenke>
                            }
                            <SearchButton/>

                            <div onClick={handleShow} className="menu_btn menu__account">
                                <img alt="Ditt login bilde" src={props.photo} className="menu__account_photo"/>
                            </div>
                        </div>
                        }

                        {!props.photo &&
                            <div className="menu__buttons">
                                <div onClick={handleShow} className="menu_btn menu__account">
                                    <img alt="Ditt login bilde" src={accountIcon} className="menu__account_photo"/>
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>

            <Modal show={show} animation={false} onHide={handleClose} className="menu-popup" centered>
                <Modal.Body>
                    <div className="menu-popup__body">
                        {state.token &&
                            <div className="menu-popup__loggedin">
                                <img src={state.photo} alt="Ditt profilbilde" />
                                <h4>{state.user}</h4>
                                <p>{state.email}</p>
                            </div>
                        }

                        {!state.token &&
                            <div className="menu-popup__not_loggedin">
                                <p>Velg konto du vil logge deg på med</p>
                                <GoogleLoginProvider/>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {state.token &&
                    <Button variant={"danger"} onClick={handleLogoutClicked}>
                        Logg av
                    </Button>
                    }
                    <Button onClick={handleClose}>
                        Lukk
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
        </div>
    );
}


export default Menu
