import {Card, CardImg, Col, Row} from "react-bootstrap";
import React from "react";
import "./MetaDetailsCard.css";
import {useBook} from "../context/BookContext";
import {ListSeparator} from "../utils/ListSeparator";

export function MetaDetailsCard() {
    const { state } = useBook();
    if (!state.meta){
        return (<div></div>)
    }
    const { meta } = state;
    return (
        <div>
            <Card className="meta-details-card">
                <Card.Header>
                    <Row>
                        <Col>Far {meta.family?.father || "ukjent"}</Col>
                        <Col>Mor {meta.family?.mother || "ukjent"}</Col>
                    </Row>
                </Card.Header>
                <CardImg src={meta?.imageURL}/>
                <Card.Footer>
                    Barn:
                    {meta.family?.children &&
                        <ListSeparator children={meta.family?.children}/>
                    }
                    {!meta.family?.children &&
                        <span>ukjent</span>
                    }
                </Card.Footer>
                <Card.Body>
                    <Card.Title>{meta?.title}</Card.Title>
                    <Card.Subtitle></Card.Subtitle>

                    <div className="card-text "
                         dangerouslySetInnerHTML={{__html: meta?.description || '' }}></div>

                </Card.Body>

            </Card>
        </div>
    );
}