import React, {useState} from "react";
import {Button, Col, FormControl, InputGroup, Modal, Row} from "react-bootstrap";
import "./SearchButton.css";
import searchIcon from "../navigation/icons/search.svg";
import {useBook} from "../context/BookContext";
import SearchHitsLoader from "./SearchHitsLoader";
import Lenke from "../utils/Lenke";
import {Actions} from "../state/Actions";

const SearchButton = () => {
    const { state, dispatch } = useBook();
    const [show, setShow] = useState(false);
    const handleClose = () => {
        dispatch({
            type: Actions.SearchKeyword,
            payload: {
                searchKeyword: undefined
            }
        });
        setShow(false);
    }
    const handleShow = () => setShow(true);
    const handleChanged = (text: string) => {
        dispatch({
            type: Actions.SearchKeyword,
            payload: {
                searchKeyword: text
            }
        });
    }
    return (
        <>
            <div className="menu_btn search-btn" onClick={handleShow}>
                <img src={searchIcon} alt="Søk"/>
            </div>
            <Modal autoFocus={true} className={"search-popup"} animation={false} show={show} centered={true} onHide={handleClose}>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <FormControl
                            value={state.searchKeyword}
                            plaintext={true}
                            onChange={(e) => handleChanged(e.target.value)}
                            placeholder="Søk i alt innhold"
                            aria-label="Søk i alt innholdr"
                        />
                    </InputGroup>
                    <div className="search-btn__results">
                        {state.searchKeyword &&
                            <SearchHitsLoader keyword={state.searchKeyword}>
                                {state.searchHits?.map( (it, index) => (
                                    <Lenke className="search-btn__hit" key={"hit_"+index} href={"/library/" + it.collection + "/" + it.bookID + "/" + it.chapter}>
                                        <Row>
                                            <Col sm={1} className="search-btn__hit-nr">{index+1}</Col>
                                            <Col sm={8} className="search-btn__hit-text">{it.text}</Col>
                                            <Col sm={3} className="search-btn__hit-location">{it.book}<br />{it.chapter}</Col>
                                        </Row>
                                    </Lenke>
                                ))}
                            </SearchHitsLoader>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className="search-btn__hit-total">
                            {state.searchKeyword &&
                                <span>Fant {state.searchHits?.length} treff</span>
                            }
                        </Col>
                        <Col >
                            <Button variant="primary" size={"sm"} onClick={handleClose}>Ferdig</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SearchButton
