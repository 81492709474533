import React, {
    createContext,
    useContext,
    useState,
    useReducer,
    useEffect
} from 'react';
import {BookState} from "../state/BookState";
import {BookReducer} from "../state/BookReducer";
import {BookAction} from "../state/BookAction";

interface BookContextProviderProps {
    children: any;
}

const useBook = () => useContext(BookContext);

const BookContext = createContext({
    state: {} as BookState,
    dispatch: (action: BookAction) => {}
    } as BookContextInterface
);

interface BookContextInterface {
    state: BookState,
    dispatch: (action: BookAction) => void
}

const BookProvider = (props: BookContextProviderProps) => {
    const [stater, dispatcher] = useReducer(BookReducer, {});
    const [state, setState] = useState({});
    const dispatch = (action: BookAction) => {
        dispatcher(action);
    }
    useEffect(()=> {
        setState(stater)
        // eslint-disable-next-line
    },[stater])

    return (
        <BookContext.Provider value={{ state, dispatch }}>
            {props.children}
        </BookContext.Provider>
    );
};

export { useBook, BookProvider };