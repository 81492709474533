import { useGoogleLogin } from 'react-use-googlelogin'
import {Actions} from "../state/Actions";
import {useBook} from "../context/BookContext";
import React, {useEffect} from "react";

const clientId = "785235782634-36pumar0a5vgo8ks9akidnnhg60medao.apps.googleusercontent.com";

const GoogleLoginProvider = () => {
    const { signIn, signOut, googleUser, isSignedIn } = useGoogleLogin({
        clientId: clientId,
    })
    const { dispatch } = useBook();
    useEffect(() => {
        if (isSignedIn) {
            dispatch({
                type: Actions.Login,
                payload: {
                    user: googleUser?.profileObj?.name,
                    email: googleUser?.profileObj?.email,
                    token: googleUser?.tokenId,
                    photo: googleUser?.profileObj?.imageUrl
                }
            })
        }
    }, [googleUser])
    return (
        <div className="google-login-provider">
            <div style={{ padding: '1rem' }}>
                <button onClick={signIn} style={{ marginRight: '1rem' }}>
                    Sign in
                </button>
                <button onClick={signOut}>Sign Out</button>
                {isSignedIn && (
                    <div>
                        <img src={googleUser?.profileObj?.imageUrl} alt="Avatar" />
                        <p>{googleUser?.profileObj?.name}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GoogleLoginProvider
