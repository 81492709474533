import {ProgressBar} from "react-bootstrap";
import React from "react";
import "./BookProgress.css";

interface BookProgressProps {
    chapter: number;
    max: number;
}

export const BookProgress = (props: BookProgressProps) => {
    return (
        <div className="book-progress">
            <ProgressBar now={props.chapter} max={props.max}/>
        </div>
    );
}