import {Alert} from "react-bootstrap";
import React from "react";
import "./ErrorCard.css";
import logo from "../navigation/icons/logo.png";

interface ErrorCardProps {
    message: string
}

export const ErrorCard = (props: ErrorCardProps) => {
    return (
        <div className="error-card">
            <Alert variant="danger" onClose={() => {}}>
                <img src={logo} alt="Applikasjonslogo"/>
                <div className="error-card__text">
                    <h3>Beklager...</h3>
                    <p>
                        {props.message}
                    </p>
                </div>
            </Alert>
        </div>
    );
}