import {Card} from "react-bootstrap";
import React from "react";
import "./ChapterContentsCard.css";

interface ChapterContentsProps {
    title: string;
    body?: string;
    children?: any
}

const ChapterContentsCard = (props: ChapterContentsProps) => {
    return (
        <Card className="chapter-contents-card">
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                {props.children &&
                    <div>
                        {props.children}
                    </div>
                }
                {!props.children &&
                    <div className="card-text chapter-card-text"
                         dangerouslySetInnerHTML={{__html: props.body || ''}}></div>
                }
            </Card.Body>
        </Card>
    );
}

export default ChapterContentsCard
