import BooksAPI from "../api/BooksAPI";
import {useBook} from "../context/BookContext";
import {Actions} from "../state/Actions";
import {useEffect} from "react";
import {BookSpinner} from "../library/BookSpinner";
import {Status} from "../api/Status";
import ErrorPage from "../security/ErrorPage";

interface CollectionLoaderProps {
    children: any;
    collectionID: string;
}

const CollectionLoader = (props: CollectionLoaderProps) => {
    const { state, dispatch } = useBook();
    useEffect(() => {
        window.scroll(0,0)
        if (!state.booksStatus || state.bookCollectionID !== props.collectionID) {
            BooksAPI.GetCollection(state.token || '', props.collectionID).then(
                response => {
                    dispatch({
                        type: Actions.GotoCollection,
                        payload: {
                            books: response.books,
                            bookCollectionID: props.collectionID,
                            status: Status.Ready
                    }});
                }
            ).catch(e => {
                dispatch({
                    type: Actions.GotoCollection,
                    payload: {
                        bookCollectionID: props.collectionID,
                        status: e.status
                    }
                });
            })
        }
        // eslint-disable-next-line
    }, [state.books, state.booksStatus, state.bookCollectionID])
    if (state.bookCollectionID !== props.collectionID){
        return (
            <BookSpinner title="Loading collection..."/>
        );
    }
    switch (state.booksStatus) {
        case Status.Forbidden:
            return (
                <ErrorPage message="You dont have access to collection"/>
            )
        case Status.Error:
            return (
                <ErrorPage message="Could not load collection!"/>
            )
        case Status.Unauthorized:
            return (
                <ErrorPage message="You must login to see collection"/>
            )
        case Status.Timeout:
            return (
                <ErrorPage message="Timeout loading collection"/>
            )
        case Status.Ready:
            return (
                <div>{props.children}</div>
            )
        default:
            return (
                <BookSpinner title="Loading collection..."/>
            );
    }
}

export default CollectionLoader
