import React from 'react';
import {Page} from "../pages/Page";
import Menu from "../navigation/Menu";
import {Col, Row} from "react-bootstrap";
import "./MapPage.css";
import {MapData} from "./MapData";
import {genesisMapData} from "./maps/GenesisMapData";
import {midjanMapData} from "./maps/MidjanMapData";
import {AnimatedMap} from "./AnimatedMap";

interface MapPageProps {
    mapData?: MapData
}

const MapPage = ({ mapData = genesisMapData }: MapPageProps) => {
    return (
        <Page className={"map-page"} fluid={false} >
            <Menu
                home={"/"}
                label={"Alexandria"}
            />
            <Row>
                <Col>
                    <AnimatedMap mapData={mapData}/>
                </Col>
            </Row>
        </Page>
    );
}

export default MapPage;
