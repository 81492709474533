import {Container} from "react-bootstrap";
import React from "react";
import {buildCollectionStyleUrl} from "../utils/buildCollectionStyleUrl";
import "./Page.css";
import {View} from "../state/View";
import {useBook} from "../context/BookContext";

interface PageProps {
    children: any
    className?: string
    backgroundImage?: string
    fluid?: boolean
}

export const Page = (props: PageProps) => {
    const { state } = useBook();
    document.body.className = props.className as string;
    const bg = state.token && props.backgroundImage ? "url("+ props.backgroundImage + ")" : ""
    return (
        <div className={"page "} style={{backgroundImage: bg}}>
            {(state.token && state.bookCollectionID && (state.view === View.Book || state.view === View.Chapter)) &&
                <link rel="stylesheet" href={buildCollectionStyleUrl(state.bookCollectionID)}/>
            }
            <Container fluid={props.fluid} className="page-container">
                {props.children}
            </Container>
        </div>
    );
}