import {useEffect} from "react";

const Swiper = () => {
    let xDown: number;
    let yDown: number;
    let swipeDirection = 0;

    function getTouches(evt: TouchEvent) {
        return evt.touches
    }
    const handleTouchStart = (evt: TouchEvent) => {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    }
    const handleTouchEnd = () => {
        switch (swipeDirection) {
            case 1: gotoNextPage(); break;
            case -1: gotoPrevPage(); break;
        }
        swipeDirection = 0;
    }
    const handleTouchMove = (evt: TouchEvent) => {
        if ( ! xDown || ! yDown ) {
            return;
        }
        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        const REQUIRED_DIFF = 0;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > REQUIRED_DIFF ) {
                /* left swipe */
                swipeDirection = 1;
            }
            if (xDiff < REQUIRED_DIFF) {
                /* right swipe */
                swipeDirection = -1;
            }
        } else {
            if ( yDiff > 0 ) {
                /* up swipe */
            } else {
                /* down swipe */
            }
        }
        /* reset values */
        xDown = -1;
        yDown = -1;
    }
    const upHandler = (key: KeyboardEvent) => {
        switch (key.code) {
            case "ArrowLeft":
                gotoPrevPage();
                break;
            case "ArrowRight":
                gotoNextPage();
                break;
        }
    };
    const gotoPrevPage = () => {
        const prevBtn = document.getElementById("prevButton")
        if (prevBtn && !isModalPopup()) {
            prevBtn.click();
        }
    };
    const gotoNextPage = () => {
        const nextBtn = document.getElementById("nextButton")
        if (nextBtn && !isModalPopup()) {
            nextBtn.click();
        }
    };
    const isModalPopup = () => {
        return !!document.querySelector(".modal-backdrop.show")
        // return false
    }
    useEffect(() => {
        window.addEventListener("keyup", upHandler);
        document.addEventListener('touchstart', handleTouchStart, false);
        document.addEventListener('touchmove', handleTouchMove, false);
        document.addEventListener('touchend', handleTouchEnd, false);
        return () => {
            window.removeEventListener("keyup", upHandler);
            window.removeEventListener("touchstart", handleTouchStart);
            window.removeEventListener("touchmove", handleTouchMove);
            window.removeEventListener("touchend", handleTouchEnd);
        };
    });

    return (<span></span>);
}

export default Swiper
