import {BookItem} from "../api/BookItem";
import {Card, Col, Row} from "react-bootstrap";
import {buildBookCoverUrl} from "../utils/buildBookCoverUrl";
import {ReadingTime} from "../utils/ReadingTime";
import React from "react";
import "./BookCard.css";

 const BookCard = (props: { collectionID: string, bookItem: BookItem }) => {
    return (
        <Card className={"collection-card"}>
            <Card.Img variant="top" src={buildBookCoverUrl(props.collectionID, props.bookItem.bookID)}/>
            <Card.Body>
                <Card.Title>{props.bookItem.subtitle}</Card.Title>
                <Card.Subtitle>{props.bookItem.title}</Card.Subtitle>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>{props.bookItem.category}</Col>
                    {/*<Col>Ord: {formatThousand(c?.wordCount)}</Col>*/}
                    <Col>
                        <ReadingTime wordCount={props.bookItem.wordCount}/>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default BookCard
