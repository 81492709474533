import {MapSize, MapLabel} from "./MapData";
import {makePercent} from "./MakePercent";
import React from "react";

interface MapTextLabelProps {
    label: MapLabel,
    size: MapSize
}

export const MapTextLabel = ({label, size}: MapTextLabelProps) => {
    return (
        <div
            style={{left: makePercent(label.x, size.width), top: makePercent(label.y, size.height)}}
            className={"map-label"}>{label.title}</div>
    )
}