import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {useParams} from "react-router-dom";
import {PathParams} from "../pages/PathParams";
import {Page} from "../pages/Page";
import {useBook} from "../context/BookContext";
import BookLoader from "./BookLoader";
import CollectionLoader from "../collection/CollectionLoader";
import LibraryLoader from "../library/LibraryLoader";
import {findBookTitle} from "../state/FindBookTitle";
import Menu from "../navigation/Menu";
import {buildCollectionCoverUrl} from "../utils/buildCollectionCoverUrl";
import {findCollectionTitle} from "../state/FindCollectionTitle";
import DocTitle from "../navigation/DocTitle";
import BookCoverCard from "./BookCoverCard";
import {buildBookCoverUrl} from "../utils/buildBookCoverUrl";
import {findBookSubtitle} from "../state/FindBookSubtitle";
import MetaBrowserPanel from "../meta/MetaBrowserPanel";
import ChaptersList from "./ChaptersList";

const BookPage = () => {
    const {state} = useBook();
    const {collectionID, bookID} = useParams<PathParams>();
    const book = state.books?.find(b => b.bookID === bookID);
    return (
        <Page className={"book-page book_" + bookID}>
            <LibraryLoader>
                <CollectionLoader collectionID={collectionID}>
                    <BookLoader collectionID={collectionID} bookID={bookID}>
                        <Menu
                            bookID={bookID}
                            label={findCollectionTitle(state)}
                            home={"/library/" + collectionID}
                            icon={buildCollectionCoverUrl(collectionID)}
                            prev={"/library/" + collectionID}
                            next={"/library/" + collectionID + "/" + bookID + "/1"}
                            photo={state.photo}
                        />
                        <Row>
                            <Col>
                                <BookCoverCard
                                    collectionID={collectionID}
                                    bookID={bookID}
                                    src={buildBookCoverUrl(collectionID, bookID)}
                                    title={findBookSubtitle(state)}
                                    subtitle={findBookTitle(state)}
                                    category={book?.category}
                                    wordCount={book?.wordCount}
                                    keywords={book?.keywords}
                                    description={book?.description}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <MetaBrowserPanel book={book} bookCollectionID={collectionID} bookID={bookID}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {state.chapters &&
                                    <ChaptersList bookID={bookID} bookCollectionID={collectionID} chapters={state.chapters}/>
                                }
                            </Col>
                        </Row>
                        <DocTitle title={state.bookCollectionID + " - " + findBookTitle(state)}/>
                    </BookLoader>
                </CollectionLoader>
            </LibraryLoader>
        </Page>
    );
};

export default BookPage
