import React from "react";
import icon from "./place.svg"
import MetaCard, {MetaCardProps} from "../MetaCard";

const PlaceCard = (props: MetaCardProps) => {
    return (
        <MetaCard icon={icon} title={props.title} bookCollectionID={props.bookCollectionID}/>
    )
}

export default PlaceCard
