import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Page} from "../pages/Page";
import Lenke from "../utils/Lenke";
import {useBook} from "../context/BookContext";
import LibraryLoader from "./LibraryLoader";
import Menu from "../navigation/Menu";
import DocTitle from "../navigation/DocTitle";
import "./LibraryPage.css";
import CollectionCard from "./CollectionCard";

const LibraryPage = () => {
    const { state } = useBook();
    return (
        <Page className="library-page">
            <LibraryLoader>
                <Menu label="Alexandria" home="/" photo={state.photo}/>
                <Row>
                    {state.bookCollections?.map(
                        (c, index) => (
                            <Col md={12} key={"collection_" + index}>
                                <Lenke  href={"/library/" + c.id}>
                                    <CollectionCard key={"bookCollection_" + index} c={c}/>
                                </Lenke>
                            </Col>
                        ))
                    }
                    <DocTitle title="Alexandria"/>
                </Row>
            </LibraryLoader>
        </Page>
    );
}

export default LibraryPage;
