import React from "react";
import icon from "./people.svg"
import MetaCard, {MetaCardProps} from "../MetaCard";

const PersonCard = (props: MetaCardProps) => {
    return (
        <MetaCard icon={icon} title={props.title} bookCollectionID={props.bookCollectionID}/>
    )
}

export default PersonCard
