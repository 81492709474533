import React from "react";

interface ReadingTimeProps {
    wordCount?: number
}

export const ReadingTime = (props: ReadingTimeProps) => {
    if (!props.wordCount) {
        return (<span className="reading-time"></span>)
    }
    return (<span className="reading-time">{Math.ceil(props.wordCount / 200)} min</span>)
};