import {BookState} from "../state/BookState";
import mapChapterHtml from "./mapChapterHtml";

export const fixMetadata = (state: BookState): string | undefined => {
    if (!state.chapterMetadata || !state.chapter) {
        return
    }
    let fixedBody = mapChapterHtml(state.chapter.body);

    for (let file of state.chapterMetadata?.html) {
        const table = '<div class="chapter-metadata-file">' + file.html + '</div>';
        fixedBody = insertByHeaderIndex(table, file.index, fixedBody);
    }

    for (let image of state.chapterMetadata?.images) {
        const img = '<div class="chapter-metadata-image"><img src="' + image.url + '"></div>';
        fixedBody = insertByHeaderIndex(img, image.index, fixedBody);
    }
    return fixedBody;
}
const findIndexByCount = (value: string, index: number, body: string): number => {
    let newPos = -1;
    let matchCount = 0;
    for (let x = 0; x < body.length; x++) {
        newPos = body.indexOf("</h2>", x);
        x = newPos;
        matchCount++;
        if (matchCount === index) {
            return newPos;
        }
    }
    return -1;
}
const insertByHeaderIndex = (value: string, index: number, body: string): string => {
    const h2index = findIndexByCount(value, index, body);
    if (h2index === -1) {
        return body;
    }
    return body.substr(0, h2index + 5) + value + body.substr(h2index);
}