import {BookState} from "./BookState";
import {BookAction} from "./BookAction";
import {Actions} from "./Actions";
import {View} from "./View";
import {fixMetadata} from "../chapter/FixMetadata";
import {Status} from "../api/Status";

const saveProps = (token: string | undefined, user: string | undefined, photo: string | undefined, email: string | undefined) => {
    localStorage.setItem("token", token || '')
    localStorage.setItem("user", user || '')
    localStorage.setItem("photo", photo || '')
    localStorage.setItem("email", email || '')
}

const clearProps = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.removeItem("photo")
    localStorage.removeItem("email")
}

export const BookReducer = (state: BookState, action: BookAction): BookState => {
    const nextState = Object.assign({}, state);
    const { payload } = action;
    switch (action.type) {
        case Actions.Reset:
            return {
                token: payload?.token,
                view: View.Library
            };
        case Actions.NextSearchIndex:
            if (nextState.searchSelectedIndex) {
                nextState.searchSelectedIndex = nextState.searchSelectedIndex + 1
            } else {
                nextState.searchSelectedIndex = 0
            }
            return nextState;
        case Actions.SearchKeyword:
            nextState.searchKeyword = payload?.searchKeyword;
            nextState.searchSelectedIndex = undefined;
            nextState.searchHits = undefined;
            nextState.searchStatus = undefined;
            return nextState;
        case Actions.GotoSearch:
            nextState.searchHits = payload?.searchHits;
            nextState.searchStatus = payload?.status;
            return nextState;
        case Actions.Meta:
            if (payload?.meta && payload.meta.length > 0){
                nextState.meta = payload?.meta[0]
            } else {
                nextState.meta = undefined;
            }
            nextState.metaID = payload?.metaID;
            nextState.metaStatus = payload?.status;
            return nextState;
        case Actions.SavedToken:
            nextState.token = payload?.token;
            nextState.user = payload?.user;
            nextState.photo = payload?.photo;
            nextState.email = payload?.email;
            nextState.tokenRead = true;
            return nextState;
        case Actions.Logout:
            clearProps();
            return {
                token: payload?.token,
                view: View.Library
            };
        case Actions.Login:
            if (payload?.token){
                nextState.token = payload.token;
                nextState.user = payload.user;
                nextState.photo = payload.photo;
                nextState.email = payload.email;
                saveProps(payload.token, payload.user, payload.photo, payload.email)
            } else {
                nextState.token = undefined;
                nextState.user = undefined;
                nextState.photo = undefined;
                nextState.email = undefined;
                clearProps()
            }

            nextState.bookCollections = [];
            nextState.bookCollectionsStatus = Status.Loading;
            return nextState;
        case Actions.GotoLibrary:
            if (!payload?.status) {
                throw new Error("Status not specified!");
            }
            nextState.view = View.Library;
            nextState.books = undefined;
            nextState.chapterNr = undefined;
            nextState.bookCollections = payload?.bookCollections;
            nextState.bookCollectionsStatus = payload.status;
            if (payload.status === Status.Unauthorized) {
                nextState.token = undefined;
            }
            return nextState;
        case Actions.GotoCollection:
            if (!payload?.bookCollectionID) {
                throw new Error("bookCollectionID not specified!");
            }
            if (!payload?.status) {
                throw new Error("Status not specified!");
            }
            nextState.view = View.Collection;
            nextState.chapterNr = undefined;
            nextState.booksStatus = payload.status;

            if (payload?.status === Status.Ready) {
                nextState.bookCollectionID = payload?.bookCollectionID;
                if (payload?.bookCollectionID && state?.bookCollections){
                    nextState.collection = state?.bookCollections?.find(bc => bc.id === payload.bookCollectionID)
                }
                nextState.books = payload?.books;
            } else {
                nextState.bookCollectionID = undefined;
                nextState.books = undefined;
            }
            return nextState;
        case Actions.GotoBook:
            if (!payload?.bookID) {
                throw new Error("bookID not specified!");
            }
            if (!payload?.status) {
                throw new Error("Status not specified!");
            }
            nextState.view = View.Book;
            nextState.bookID = payload?.bookID;
            nextState.chapters = payload?.chapters;
            nextState.bookStatus = payload.status;
            return nextState;
        case Actions.GotoChapter:
            if (!payload?.bookCollectionID) {
                throw new Error("bookCollectionID not specified!");
            }
            if (!payload?.bookID) {
                throw new Error("bookID not specified!");
            }
            if (!payload?.chapterNr) {
                throw new Error("chapterNr not specified!");
            }
            if (!payload?.status) {
                throw new Error("Status not specified!");
            }
            nextState.chapterStatus = payload.status;
            nextState.chapterNr = payload.chapterNr;
            nextState.view = View.Chapter;
            nextState.bookID = payload?.bookID;
            nextState.prevUrl = undefined;
            nextState.nextUrl = undefined;
            nextState.meta = undefined;
            nextState.metaStatus = undefined;

            if (!nextState.chapters){
                throw new Error("BookPage not loaded");
            }

            if (payload.status === Status.Ready) {
                nextState.chapter = payload?.chapter;
                if (nextState.chapterNr > 1) {
                    nextState.prevUrl = "/library/" + nextState.bookCollectionID + "/" + nextState.bookID + "/" + (nextState.chapterNr-1);
                }
                const max =  nextState.chapters[nextState.chapters.length - 1]["chapterNr"] | 0;
                if (nextState?.chapterNr < max) {
                    nextState.nextUrl = "/library/" + nextState.bookCollectionID + "/" + nextState.bookID + "/" + (nextState.chapterNr+1);
                }
            } else {
                nextState.chapter = undefined;
            }

            return nextState;
        case Actions.Metadata:
            if (!payload?.bookCollectionID) {
                throw new Error("bookCollectionID not specified!");
            }
            if (!payload?.bookID) {
                throw new Error("bookID not specified!");
            }
            if (!payload?.chapterNr) {
                throw new Error("chapterNr not specified!");
            }
            if (!payload?.status) {
                throw new Error("Status not specified!");
            }
            nextState.view = View.Chapter;
            nextState.chapterMetadata = payload?.metadata;
            nextState.chapterHtml = fixMetadata(nextState);
            return nextState;
    }
    return nextState;
}

