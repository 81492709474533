import {Page} from "../pages/Page";
import {Button, Card, Col, Row} from "react-bootstrap";
import Menu from "../navigation/Menu";
import DocTitle from "../navigation/DocTitle";
import React from "react";
import {useBook} from "../context/BookContext";
import alexandria from "./kevin-kok-alx-17.jpg";
import "./WelcomePage.css";

const WelcomePage = () => {
    const {state} = useBook();
    return (
        <Page className="welcome-page">
            <Menu
                label="Alexandria"
                home="/"
                photo={state.photo}
            />
            <Row>
                <Col>
                    <DocTitle title="Alexandria"/>

                    <Card className="welcome-card">
                        <Card.Img src={alexandria}/>
                        <Card.Header>
                        </Card.Header>
                        <Card.Body>
                            <Card.Subtitle>Alexandria</Card.Subtitle>
                            <Card.Title>Det kongelige bibliotek</Card.Title>
                            <Card.Text>
                                Det kongelige biblioteket i Alexandria skal ha vært det største biblioteket i antikken.
                                Biblioteket var knyttet til Museion, som antagelig var den største intellektuelle
                                institusjonen i antikken.
                                Museion var et sted for musikk og poesi, inneholdt en filosofisk skole og et bibliotek
                                som i Platons filosofiskole,
                                foruten også et galleri for hellige skrifter.
                            </Card.Text>
                            <Card.Footer>
                                {state.token &&
                                    <Button href="/library">Gå til bibliotek</Button>
                                }
                            </Card.Footer>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Page>
    )
}

export default WelcomePage
