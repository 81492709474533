import BooksAPI from "../api/BooksAPI";
import {useBook} from "../context/BookContext";
import {Actions} from "../state/Actions";
import {BookSpinner} from "./BookSpinner";
import React, {useEffect} from "react";
import {Status} from "../api/Status";
import {Redirect} from "react-router-dom";
import ErrorPage from "../security/ErrorPage";

interface LibraryLoaderProps {
    children: any
}

const LibraryLoader = (props: LibraryLoaderProps) => {
    const { state, dispatch } = useBook();
    useEffect(() => {
        window.scroll(0,0)
        if (!state.bookCollectionsStatus) {
            BooksAPI.GetLibrary(state.token || '').then(
                response => {
                    dispatch({
                        type: Actions.GotoLibrary,
                        payload: {
                            status: Status.Ready,
                            bookCollections: response.bookCollections
                        }
                    });
                }
            ).catch(e => {
                dispatch({
                    type: Actions.GotoLibrary,
                    payload: {
                        status: e.status
                    }
                });
            })
        }
        // eslint-disable-next-line
    }, [state.bookCollectionsStatus])
    if (!state.token) {
        return (
            <Redirect to="/login"/>
        )
    }
    switch (state.bookCollectionsStatus) {
        case Status.Forbidden:
            return (
                <ErrorPage message="Du har ikke tilgang til biblioteket"/>
            )
        case Status.Error:
            return (
                <ErrorPage message="Klarte ikke hente bibliotek"/>
            )
        case Status.Unauthorized:
            return (
                <Redirect to="/"/>
            )
        case Status.Timeout:
            return (
                <ErrorPage message="Klarte ikke hente bibliotek (timeout)"/>
            )
        case Status.Ready:
            return (
                <div>{props.children}</div>
            )
        default:
            return (
                <BookSpinner title="Loading library..."/>
            );
    }
}

export default LibraryLoader
