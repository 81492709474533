import {Link} from "react-router-dom";
import React from "react";
import "./Lenke.css";

interface LenkeProps {
    href: string,
    id?: string,
    className?: string,
    disabled?: boolean,
    children: any
}

const Lenke = (props: LenkeProps) => {
    if (props.disabled === true) {
        return (<span id={props.id} className={'lenke disabled nav-link ' + props.className}>{props.children}</span>)
    }
    return (
        <Link id={props.id} className={'lenke nav-link ' + props.className} to={props.href}>{props.children}</Link>
    );
}

export default Lenke
