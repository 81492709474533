import {BookItem} from "../api/BookItem";
import React from "react";
import "./MetaBrowserPanel.css";
import "./MetaTabs.css";
import ItemCard from "./items/ItemCard";
import PlaceCard from "./places/PlaceCard";
import PersonCard from "./people/PersonCard";
import { Col, Row, Tab, Tabs} from "react-bootstrap";
import {ListSeparator} from "../utils/ListSeparator";
import Lenke from "../utils/Lenke";

interface MetaPanelProps {
    book?: BookItem
    bookCollectionID: string;
    bookID: string;
}

const MetaPanel = ({ book, bookCollectionID, bookID }: MetaPanelProps) => {
    if (!book) {
        return (<div></div>)
    }
    return (
        <div className={"meta-browser-panel"}>

            <Tabs defaultActiveKey="places" className="meta-tabs">
                <Tab eventKey="places" title="Steder" disabled={!book.places}>
                    <Row>
                        {book.places?.map(
                            (item, index: number) => (
                                <Col md={3} xs={4} key={"place_" + index}>
                                    <Lenke href={"/metadata/" + bookCollectionID + "/" + bookID + "/" + item}>
                                        <PlaceCard bookCollectionID={bookCollectionID} title={item}/>
                                    </Lenke>
                                </Col>
                            ))}
                    </Row>
                </Tab>
                <Tab eventKey="people" title="Personer" disabled={!book.people}>
                    <Row>
                        {book.people?.map(
                            (item, index: number) => (
                                <Col md={3} xs={4} key={"person_" + index}>
                                    <Lenke href={"/metadata/" + bookCollectionID + "/" + bookID + "/" + item}>
                                        <PersonCard bookCollectionID={bookCollectionID} title={item}/>
                                    </Lenke>
                                </Col>
                            ))}
                    </Row>
                </Tab>
                <Tab eventKey="contact" title="Ting" disabled={!book.items}>
                    <Row>
                        {book.items?.map(
                            (item, index: number) => (
                                <Col md={3} xs={4} key={"item_" + index}>
                                    <Lenke href={"/metadata/" + bookCollectionID + "/" + bookID + "/" + item}>
                                        <ItemCard bookCollectionID={bookCollectionID} title={item}/>
                                    </Lenke>
                                </Col>
                            ))}
                    </Row>
                </Tab>
                <Tab eventKey="keywords" title="Stikkord">
                    <ListSeparator className="meta-browser-panel__keywords"
                                   keywords={book.autoKeywords?.sort()}>, </ListSeparator>
                </Tab>
            </Tabs>

        </div>
    )
}

export default MetaPanel
